import React, {useState,useEffect, useRef} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import Select from "react-select";
import DatePickerWithTimezone from "../DatePickerWithTimezone";
import moment from "moment-timezone";

function DueDate(props){

  const {isRestricted, firstTimeRecurring, due_date_select_value, due_date_options,
    select_styles, due_date, default_due_time, date_format, timezone, time_format,
    due_time, name, id, handleInputChange, input_name, minDate, maxDate} = props;

  let due_date_options_formated = due_date_options
  let format_due_options = name === "Start On"

  return(
    <Form.Group as={Row} className={firstTimeRecurring ? "d-flex flex-column w-100 gap-3" : "mb-2"} controlId="dueDate">
      <Form.Label column sm={firstTimeRecurring ? "12" : "3"}>
        <div className="row">
          <div className="col-12">
            {name}
          </div>
        </div>
      </Form.Label>
      <Col sm={firstTimeRecurring ? "12" : "9"}
           className={isRestricted ? "restricted-container d-flex pe-0" : "d-flex pe-0"}>
        <div className="row d-flex w-100">
          <div className={"col pe-0"}>
            {format_due_options
              ?
              <Form.Label className="add-line-height-35">
                Pick a date
              </Form.Label>
              :
            <Select id="due_date"
                    name='due_date'
                    value={due_date_options_formated.filter(function (option) {
                      return option.value === (format_due_options ? "custom" : (isNaN(new Date(due_date_select_value)) ? due_date_select_value : "custom"));
                    })}
                    options={due_date_options_formated}
                    onChange={(e) => handleInputChange(input_name + "_date", e.value)}
                    styles={select_styles}
            />

            }

          </div>
          {((due_date_select_value === "custom" || !["today", "tomorrow", "next_week"].includes(due_date_select_value)) || format_due_options) &&
            <div className={"col pe-0"}>
              <DatePickerWithTimezone
                selected={isNaN(new Date(due_date)) ?
                  due_date == "today" ?
                    moment.tz(timezone).format()
                    : moment().tz(timezone).hour(parseInt(default_due_time["h"])).minute(parseInt(default_due_time["m"])).format()
                  : due_date}
                onChange={(date) => handleInputChange(input_name + "_date", date)}
                dateFormat={date_format}
                timezone={timezone}
                className="form-control"
                minDate={moment(minDate).tz(timezone).toDate()}
                maxDate={moment(maxDate).tz(timezone).toDate()}
              />
            </div>
          }
          <div className="col pe-0">
            <DatePickerWithTimezone
              selected={isNaN(new Date(due_time)) ?
                isNaN(new Date(due_date)) ?
                  due_date == "today" ?
                    moment.tz(timezone).format()
                    : moment().tz(timezone).hour(parseInt(default_due_time["h"])).minute(parseInt(default_due_time["m"])).format()
                  : due_date
                : due_time}
              onChange={(date) => handleInputChange(input_name + "_time", date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timezone={timezone}
              dateFormat={time_format}
              className="form-control width_85px"
            />
          </div>
        </div>
      </Col>
    </Form.Group>
)
}
export default DueDate