import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import _, { includes } from 'lodash';
import Select from 'react-select';
import { SketchPicker } from 'react-color'; //import the desired picker
import TextareaAutosize from 'react-textarea-autosize';
import LogosModalContainer from '../containers/LogosModalContainer'

//stock_order_categories: [StockOrderCategory.stock_order_items[StockOrderItem{ :stock_order_quantities, :stock_order_metadata }]])]
//categories_list: { [Category] } (nested)
//categories_by_id: { [id] : Category(id, name, parent_id) } 
//categories_attributes : { [category_id]: { [metadatum_id]: metadatum_name }}
//sizing_table: { [id, name, values] }
function DetailedEntry(props) {
  const { stock_order_categories, categories_attributes, categories_by_id, sizing_table, modal_logos_open} = props;
  const handleCheckboxInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }
  const sizing_is_present = _.find(stock_order_categories, (soc) => soc.stock_order_sizing_table_id)

  const presetColors = ['#FFFFFF', '#000080', '#3066BE', '#A2D5FA', '#CE0000', '#ffcbcb', '#8B0000', '#BDB76C', '#F78764', '#FE6F1D', '#FFE600', '#155C29', '#62C370', '#C2F9BB', '#40E0D0', '#FD97AF', '#DFA4EF', '#8B3BA0', '#4B065E', '#CC7722', '#83781B', '#FFFFF0', '#C2B280', '#ECD8AE', '#5F2C0F', '#A7A8AA', '#000000']

  let max_qty_values = 0
  stock_order_categories.map((soc) => {
    if(sizing_table[soc.stock_order_sizing_table_id])
      max_qty_values = (max_qty_values < sizing_table[soc.stock_order_sizing_table_id].values.length) ? sizing_table[soc.stock_order_sizing_table_id].values.length : max_qty_values
  })
  
  return (<>
      {modal_logos_open && <LogosModalContainer handleLogoChange={props.handleLogoChange} current_logos={props.logos}/>}
      <Table bordered style={{minWidth: sizing_is_present ? "1500px" : "500px"}} className="detailed_entry_table">
        <thead className="backgroundColorGemsBlackGrey">
          <tr>
            {/* <th></th> */}
            <th className='width_55px'>Line #</th>
            <th rowSpan="1" className='min_width_105px'>
              SKU / Style #
            </th>
            <th style={{ minWidth: "200px", width: "30%" }} rowSpan="1" >
              Description
            </th>
            <th rowSpan="1">
              Attributes
            </th>
            <th rowSpan="1" style={{zIndex: 1}}>
              Color Group
            </th>
            <th rowSpan="1" style={{zIndex: 1}}>
              Quantities
            </th>
            <th rowSpan="1" >
              Logo
            </th>
            <th rowSpan="1" >
              Logo Cost
            </th>
            <th rowSpan="1" >
              Cost Per Item
            </th>
            <th rowSpan="1" >
              Total Quantity
            </th>
            <th rowSpan="1" >
              Total Cost
            </th>
            <th rowSpan="1" >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
        { 
          stock_order_categories.map((soc, index_soc) => (
            !soc["deleted"] && <>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.06)"}}>
                <td rowSpan="1" colSpan="5">
                  <b>{ soc.category_name }</b>
                </td>
                <td rowSpan="1" colSpan="1">
                  <Select id = "stock_order_sizing_table_id" 
                                  menuPortalTarget={document.getElementById('StockOrderForm')}
                                  menuPosition={'fixed'} 
                                  name = 'stock_order_sizing_table_id'
                                  isDisabled={props.view_only_stock_orders}
                                  value={sizing_table_options(sizing_table).filter(function(option) {
                                    return option.value === (soc.stock_order_sizing_table_id || "");
                                  })}
                                  options={sizing_table_options(sizing_table)} 
                                  onChange={(e) => {props.handleSizingSelectChange(e.value, soc.category_id)}}/>
                </td>
                <td rowSpan="1" colSpan="3"></td>
                <td rowSpan="1" className='text-center'>{soc.qty}</td>
                <td rowSpan="1" className='text-center'>{parseFloat(soc.total_cost).toFixed(2)}</td>
                <td rowSpan="1" className='text-center'>
                <div className="dropdown">
                    <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {`dropdownContainerItemMenu_${soc.category_id}`} />
                    <ul className="dropdown-menu" aria-labelledby = {`dropdownContainerItemMenu_${soc.category_id}`} >
                      {/* <li className="dropdown-item">
                        <a className="ms-3 link" onClick={props.handleRemove} >Select All</a>
                      </li> */}
                      <li className="dropdown-item">
                        <a className="ms-3 link color_red" onClick={() => props.handleRemove(soc.category_id, null)}>Remove All</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.03)"}}>
                {/* <td></td> */}
                <td></td>
                <td></td>
                <td></td>
                <td style={{minWidth: categories_attributes[soc.category_id] ? "" : ""}}>
                  <div className='d-flex'>
                  {categories_attributes[soc.category_id]
                      ?
                      (categories_attributes[soc.category_id]).map((attr) => (
                      <div className="mx-2 text-center" style={{ minWidth: "100px", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {attr.name}
                      </div>
                      ))
                      :
                      <>-</>
                      }
                  </div>
                </td>
                <td></td>
                <td className='d-flex' style={{ fontWeight: "bold" }}> 
                  {
                  sizing_table[soc.stock_order_sizing_table_id] ?
                  (sizing_table[soc.stock_order_sizing_table_id]).values.map((br) => (
                    <div className='width_50px pe-1 text-center'>{br}</div>
                  )) 
                  :
                  "Quantity"
                  }
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <>
              {
                soc.stock_order_items.map((soi, index_soi) => (
                  !soi["deleted"] &&
                  <tr key={soi.id} >
                    {/* <td className='width_50px'>
                      <Form.Check 
                      value = {false}
                      defaultChecked = {false}
                      type='checkbox'
                      className="flex_center add-line-height-35 p-0"
                      onChange={ handleCheckboxInputChange } 
                      />
                    </td> */}
                    <td className={ soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      { soi.cancellation_date
                      ?
                      <div>
                      { soi.line }
                      </div>
                      :
                      <Form.Control type="number" 
                            name='line'
                            disabled={props.view_only_stock_orders}
                            defaultValue={ soi.line}
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            onBlur={(e) => props.sortItems(soc.category_id)}
                            />
                      }
                    </td>
                    <td className={ soi.cancellation_date ? "text-decoration-line-through width_105px" : "width_105px"}>
                      { soi.cancellation_date
                      ?
                      <div>
                      { soi.sku }
                      </div>
                      :
                      <Form.Control type="text" 
                            name='sku'
                            disabled={props.view_only_stock_orders}
                            value={ soi.sku }
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            />
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_250px" : "width_250px"}>
                        {soi.cancellation_date
                        ?
                        <div>
                          { soi.description }
                        </div>
                        :
                        <TextareaAutosize
                          name='description'
                          disabled={props.view_only_stock_orders}
                          className="w-100 autores"
                          style={{ minRows: 1 }}
                          value={ soi.description}
                          onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )} />
                        }
                    </td>
                    <td>
                      <div className='d-flex'>
                        { categories_attributes[soc.category_id]
                        ?
                          (categories_attributes[soc.category_id]).map((attr) => (
                          <div style={{ width: "100px" }} className="mx-2">
                          {soi.cancellation_date
                          ?
                          <div className="text-decoration-line-through text-center">
                            {(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                          </div>
                          :
                          (attr.name && (attr.name.toLowerCase().replace(" ", "") == "color"))
                          ?
                            <TextareaAutosize
                              name={`${attr.id}`}
                              className="w-100 autores"
                              disabled={props.view_only_stock_orders}
                              style={{ minRows: 1 }}
                              defaultValue={(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                              onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value, true )} />
                          :
                            attr.object_type == "dropdown"
                            ?
                              <Select id ={`${attr.id}`}
                                  menuPortalTarget={document.getElementById('StockOrderForm')}
                                  menuPosition={'fixed'} 
                                  name = {`${attr.id}`}
                                  isDisabled={props.view_only_stock_orders}
                                  value={attribute_options(attr.values, (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]).filter(function(option) {
                                    return option.value === (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"];
                                  })}
                                  options={attribute_options(attr.values, (_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"])} 
                                  onChange={(e) => {props.handleInputChange(soc.category_id, soi.id, attr.id, e.value, true )}}/>
                            :
                              <Form.Control type="text" 
                                defaultValue={(_.find(soi.attributes, (at) => at.metadatum_id == attr.id) || {values: ""})["values"]}
                                name={`${attr.id}`}
                                disabled={props.view_only_stock_orders}
                                onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value, true )} 
                                />
                          }
                          </div>
                          ))
                        :
                          <>-</>
                        }
                      </div>
                      </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date || props.view_only_stock_orders
                      ?
                      <div className="dropdown stock_order_item_color_group_class flex_center">
                        <div className="calendar_color_class add_padding_all_14" style={{backgroundColor: soi.color_group || '#9B9B9B'}} />
                      </div>
                      :
                      <div className="dropdown stock_order_item_color_group_class flex_center">
                        <div className="calendar_color_class add_padding_all_14" style={{backgroundColor: soi.color_group || '#9B9B9B'}} aria-expanded = "false" data-bs-toggle = "dropdown" id = {`dropdownContainerItemMenu_${soc.category_id}`} />
                        <ul className="dropdown-menu" aria-labelledby = {`dropdownContainerItemMenu_${soc.category_id}`} >
                          <li className="dropdown-item">
                            <SketchPicker
                            color={soi.color_group || '#9B9B9B'}
                            disableAlpha={true}
                            width={170}
                            presetColors={presetColors}
                            onChange={(e) => props.handleColorGroupChange(soc.category_id, soi.id, e.hex)}
                          />
                          </li>
                        </ul>
                      </div>
                      }
                    </td>
                    <td className={ soi.cancellation_date ? "text-decoration-line-through" : ""}>
                      <div className='d-flex'>
                      { soi.cancellation_date
                        ?
                          sizing_table[soc.stock_order_sizing_table_id] ?
                            sizing_table[soc.stock_order_sizing_table_id].values.map((br) => (
                              <div className='width_50px pe-1 text-center'>
                                {(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).qty}  
                              </div>
                            ))
                          : 
                          <div className='width_50px pe-1 text-center'>
                            {soi.qty}
                          </div>  
                        :
                          sizing_table[soc.stock_order_sizing_table_id] ?
                            sizing_table[soc.stock_order_sizing_table_id].values.map((br) => (
                              <div className='width_50px pe-1'>
                                <Form.Control type="number" 
                                    name='qty'
                                    disabled={props.view_only_stock_orders}
                                    className="text-center px-1" 
                                    placeholder='0'
                                    value={ (_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).qty || "" }
                                    onChange={ (e) => props.handleQuantity(soc.category_id, soi.id, br, e.target.value )} 
                                    />
                              </div>
                            ))
                          : 
                            <Form.Control type="number" 
                            name='qty'
                            disabled={props.view_only_stock_orders}
                            className="width_50px px-1 text-center"
                            defaultValue={soi.qty}
                            placeholder='0'
                            onChange={(e) => props.handleQuantity(soc.category_id, soi.id, null, e.target.value )} 
                            />

                        }
                      </div>
                    </td>
                    <td className='width_50px text-center'>
                      <>
                      {_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) &&
                      <div className="logo_container width_50px mb-1" style={{backgroundImage: `url(${((_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"])})`, dataSrc: (_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"], height: (_.find(props.logos, (l) =>  (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || {url: null})["url"] ? "60px" : "auto"}}></div>
                      }
                      {!soi.cancellation_date && !props.view_only_stock_orders && <a className="fa fa-pencil" onClick={(e) => props.openLogosModal(soi, soc.category_id)}></a>}
                      {!soi.cancellation_date && !props.view_only_stock_orders && soi.logo_id && <a className="fa fa-remove color_red ms-2" onClick={(e) => props.handleLogoChange(soc.category_id, soi.id, null, "")}></a>}
                      </>
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date
                      ?
                      <div>
                      ${soi.logo_cost}
                      </div>
                      :
                      <Form.Control type="text" 
                            className='px-1 text-center'
                            name='logo_cost'
                            disabled={props.view_only_stock_orders}
                            value={soi.logo_cost}
                            onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )}
                            />
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px" : "width_50px"}>
                      {soi.cancellation_date
                      ?
                      <div>
                      ${soi.cost}
                      </div>
                      :
                      <div className='width_70px pe-1 text-center d-flex'>
                        <Form.Control type="text" 
                              className='px-1 text-center'
                              name='cost'
                              disabled={props.view_only_stock_orders}
                              value={soi.cost}
                              onChange={(e) => props.handleInputChange(soc.category_id, soi.id, e.target.name, e.target.value )} 
                              />
                      </div>
                      }
                    </td>
                    <td className={soi.cancellation_date ? "text-decoration-line-through width_50px text-center" : "width_50px text-center"}>{soi.qty}</td>
                    <td>
                      <div className={soi.cancellation_date ? "text-decoration-line-through text-center" : "text-center"} style={{ width: "70px" }}>
                      {
                      sizing_table[soc.stock_order_sizing_table_id] ? 
                        (_.sum(_.map(soi.stock_order_quantities, (q) => parseInt(q.qty) || 0)) * (parseFloat((soi.cost || 0.0))+parseFloat((soi.logo_cost || 0)))).toFixed(2)
                      :
                        ((parseFloat((soi.cost || 0.0))+parseFloat((soi.logo_cost || 0))) * parseInt(soi.qty || 0)).toFixed(2)
                      }
                      </div>
                    </td>
                    <td className="width_50px">
                      {/* <div className="dropdown">
                        <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {"dropdownContainerItemMenu_"+soi.id} />
                        <ul className="dropdown-menu" aria-labelledby = {"dropdownContainerItemMenu_"+soi.id}>
                          <li className="dropdown-item">
                            {soi.cancellation_date ? 
                            <a className="link" onClick={props.handleRestore} category_id={soi.category_id} qty={soi.qty} total_cost={soi.total_cost}>
                              Restore
                            </a> 
                            : ""}
                            </li>
                          <li className="dropdown-item">
                            <a className="ms-3 link color_red" onClick={props.handleRemove}>Remove</a>
                          </li>
                        </ul>
                      </div> */}
                      {
                        ((soi.sku && soi.sku.trim() !== "") || (soi.description && soi.description.trim() !== ""))
                        &&
                        <>
                          {
                          soi["id"] === (soc.stock_order_items.filter((el) => !el.deleted).slice(-1)[0]["id"])
                          &&
                          <>
                            {!props.view_only_stock_orders && <a className="link" onClick={ () => props.handleNewItem(soc.category_id) }>
                              Add
                            </a>}
                            <br/>
                          </>
                          }
                          {!soi.cancellation_date &&
                            <>
                            {!props.view_only_stock_orders && <a className="link color_purple" onClick={ () => props.handleNewItem(soc.category_id, soi.id) }>
                              Duplicate
                            </a>}
                            <br/>
                          </>}
                        </>
                      }
                      {
                        soi.cancellation_date
                        &&
                        <>
                          <a className="link color_green" onClick={() => props.handleRestore(soc.category_id, soi.id)} category_id={soi.category_id} qty={soi.qty} total_cost={parseFloat(soi.total_cost).toFixed(2)}>
                            Restore
                          </a>
                          <br/>
                        </>
                      }
                      {!props.view_only_stock_orders && <a className="link color_red" onClick={ () => props.handleRemove(soc.category_id, soi.id) }>Remove</a>}
                    </td>
                  </tr>
                ))
              }
              </>
              {sizing_table[soc.stock_order_sizing_table_id] ?
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.01)"}}>
                <td colSpan="5"><b>Total quantities by sizes:</b></td>
                <td className='row m-0'>
                  {
                  (sizing_table[soc.stock_order_sizing_table_id]).values.map((br) => (
                    (soc.total_qty_by_sizes) ?
                    <div className="width_50px pe-1 text-center">{soc.total_qty_by_sizes[br] || 0}</div>
                    :
                    <></>
                  )) 
                  }
                </td>
              </tr>
              :
              <></>
              }
            </>
          ))
        }
        </tbody>
      </Table>
      <div className='row text-end m-0'>
        <div className='p-0 fs-6'>Total Items: <b>{props.total_items}</b> Total Cost: <b>{parseFloat(props.total_cost).toFixed(2)}</b></div>
      </div>
      </>
  )
} 


let sizing_table_options = (sizing_table) => {
  let sizing_table_options_arr = [{label: "One size", value: ""}]

  Object.values(sizing_table).map((st) => {
    sizing_table_options_arr.push({label: st.name, value: st.id})
  })

  return sizing_table_options_arr
}

let attribute_options = (options, actual_value) => {
  let attribute_options_arr = []
  attribute_options_arr = options.includes(actual_value) ? [] : [{label: actual_value, value: actual_value}]

  options.map((st) => {
    attribute_options_arr.push({label: st, value: st})
  })

  return attribute_options_arr
}

export default DetailedEntry