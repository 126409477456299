import React from 'react'
import missingImage from '../../../../assets/images/missing_product_image.png'
import DesktopCartConfirmation from "./DesktopCartConfirmation";

function Header (props){
  const {
    fontColor,
    formTitle,
    formDisplayBanner,
    formBanner,
    formType,
    currencySymbol,
    isCartView,
    itemsInCart,
    cartCurrentCredit,
    newItemAddedToCart,
    cartProducts,
    totalCost,
    preview,

    changeCartView,
    goToCartPopover,
    closeCartPopover,
    removeProductFromCart,
  } = props;

  const handleChangeCartView = () => {
    changeCartView(!isCartView)
  }


  return (
    <>
      {/* Header */}
      <div id='form-header' className={`w-100`} style={{color: `${fontColor}`}}>
        <div className="d-flex align-items-center header-title"
             style={{backgroundColor: "white", color: `${fontColor}`, borderBottom: `2px solid ${fontColor}`, gap: "16px"}}>
          <b className='flex-grow-1 float-start d-flex align-items-center' style={{gap: "5px"}}>
            <span className={'mobile_view'} style={{overflow: 'hidden', maxHeight: '40px', textOverflow: 'ellipsis'}}>
              {formTitle}
            </span>
            <span className={'desktop_view'}>
              {formTitle}
            </span>
            {
              isCartView && <>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
                <span>Cart</span>
              </>
            }
          </b>

          <span className=''
                style={{minWidth: `fit-content`, display: "flex", alignItems: "center"}}>
            {!isCartView && <span style={{gap: "8px", display: 'flex', alignItems: "center"}}>
              {['points', 'credit', 'credit_and_purchase'].includes(formType) && <>
                <span className='mobile_view' id='cart_view_credit' style={{
                  backgroundColor: `${cartCurrentCredit > 0 ? "#2C6730" : "#F11120"}`,
                  padding: "2px 8px",
                  borderRadius: "2px",
                  color: "white"
                }}>
                  {formType.includes('credit') ? ((currencySymbol === '$' || currencySymbol === '£') ? `${currencySymbol}${cartCurrentCredit}` : `${cartCurrentCredit}${currencySymbol}`) : cartCurrentCredit}
                </span>

                <span className='desktop_view' id='cart_view_credit' style={{
                  backgroundColor: `${cartCurrentCredit > 0 ? "#2C6730" : "#F11120"}`,
                  padding: "2px 8px",
                  borderRadius: "2px",
                  color: "white"
                }}>
                  {formType.includes('credit') ? ((currencySymbol === '$' || currencySymbol === '£') ? `${currencySymbol}${cartCurrentCredit}` : `${cartCurrentCredit}${currencySymbol}`) : cartCurrentCredit} {formType.includes('credit') ? 'Credits' : 'Points'} Remaining
                </span>

                <i className='fa fa-circle' style={{color: `${fontColor}`, fontSize: "5px"}}/>
              </>
              }

              <span className='cursor_pointer' id='cart_view_text' onClick={handleChangeCartView}>
                Cart
              </span>
              <span className={'cursor_pointer'} onClick={handleChangeCartView}>
                <i className='fa fa-shopping-cart cart_icon_title '
                   style={{color: `${fontColor}`}}/>
                <span className=""> ({itemsInCart}) </span>

              </span>


            </span>}
            {isCartView && <>
              <i className='fa fa-times remove_cart_view cursor_pointer' style={{color: `${fontColor}`, minWidth: "20px"}} onClick={handleChangeCartView}/>
            </>}
          </span>

          { newItemAddedToCart &&
            <DesktopCartConfirmation
              fontColor={fontColor}
              formType={formType}
              currencySymbol={currencySymbol}
              itemsInCart={itemsInCart}
              cartProducts={cartProducts}
              totalCost={totalCost}
              goToCartPopover={goToCartPopover}
              closeCartPopover={closeCartPopover}
              removeProductFromCart={removeProductFromCart}
            />
          }

        </div>
      </div>

    </>
  )
}


export default Header