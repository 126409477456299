import React, { useEffect } from 'react'
import {Col, Popover, Row} from 'react-bootstrap';
import missingImage from '../../../../assets/images/missing_product_image.png'

function DesktopCartConfirmation (props){
  const {
    fontColor,
    formType,
    currencySymbol,
    itemsInCart,
    cartProducts,
    totalCost,

    goToCartPopover,
    closeCartPopover,
    removeProductFromCart,
  } = props;

  return (
    <Popover
      id="popover-cart-desktop"
      placement="bottom"
      positionRight={100}
      positionTop={75}
      title="Popover bottom"
      style={{left: "auto", minWidth: "450px", maxWidth: "500px"}}
      className='desktop_view'
    >
      <div className='m-0' style={{padding: "0px 20px 20px 20px"}}>
        <div>
          <div className='py-2'>


            <div className='d-flex justify-content-between align-items-center align-self-stretch'>
              <span className='float-start bold'> Added to Cart </span>
              <span className='float-end bold' style={{color: `${fontColor}`}}> {itemsInCart} items </span>
            </div>
          </div>

          <div style={{
            maxHeight: "350px",
            paddingRight: (itemsInCart > 2 ? "16px" : "0"),
            overflow: "auto",
            color: "black"
          }} className='d-flex align-items-start flex-wrap'>
            {cartProducts.sort((a, b) => a.insertedOrder - b.insertedOrder).map((product, index) => !product.deleted && !product.canceled_at &&
              <div key={index}
                   className={`border-top w-100 ${index === (cartProducts.length - 1) ? "border-bottom" : ""}`}
                   style={{display: "flex", padding: "14px 0px", alignItems: "center", gap: "12px"}}>
                <div className='d-flex justify-content-center align-items-center'
                     style={{padding: "8px 0", gap: "16px"}}>
                  <img src={product.image ? product.image : missingImage}
                       style={{width: "75px", height: "75px", objectFit: "contain"}}/>
                </div>
                <div className='d-flex align-items-start align-self-stretch flex-grow-1' style={{gap: "12px"}}>
                  <div
                    className='d-flex flex-column justify-content-between align-items-start align-self-stretch'
                    style={{paddingTop: "6px", width: "75%"}}>
                    <div className='d-flex flex-column align-items-start align-self-stretch'
                         style={{gap: "6px"}}>
                      <span className='bold'>{product.name}</span>
                      {
                        product.configuration.map((field, index) => <span>
                          {field.name}: {field.value}
                          <br/>
                        </span>)
                      }
                    </div>
                  </div>
                  <div className='d-flex align-items-start flex-column' style={{gap: "8px", width: "25%"}}>
                    <div className='d-flex align-items-center w-100' style={{gap: "8px"}}>
                      <div className='flex-grow-1'>
                        Qty: {product.quantity}
                      </div>
                      <div className=''>
                        <i className='fa fa-trash color_red' onClick={() => removeProductFromCart(product)}/>
                      </div>
                    </div>
                    {formType !== 'no_cost' && <div style={{color: `${fontColor}`}}>
                      <span className='bold'>
                        {
                          formType === 'points' ?
                            <>{product.price} Points</>
                            :
                            <>{(currencySymbol === '$' || currencySymbol === '£') ? `${currencySymbol}${product.price}` : `${product.price}${currencySymbol}`}</>
                        }
                      </span>
                    </div>}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex justify-content-between align-items-center align-self-stretch'
               style={{padding: "14px 0"}}>
            {formType !== 'no_cost' && <div className='d-flex align-items-center' style={{gap: "12px"}}>
              <span className='bold'>
                TOTAL
              </span>
              <span style={{color: `${fontColor}`}}>
                {
                  formType === 'points' ?
                    <>{totalCost} Points</>
                    :
                    <>{(currencySymbol === '$' || currencySymbol === '£') ? `${currencySymbol}${totalCost}` : `${totalCost}${currencySymbol}`}</>
                }
              </span>
            </div>}
            <div>
              <button type="button" className='btn btn-sm me-2'
                      style={{backgroundColor: "white", color: `${fontColor}`, borderColor: `${fontColor}`}}
                      onClick={() => closeCartPopover()}>Continue Shopping
              </button>
              <button type="button" className='btn btn-sm'
                      style={{backgroundColor: `${fontColor}`, color: "white"}}
                      onClick={() => goToCartPopover()}>Go to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  )
}


export default DesktopCartConfirmation